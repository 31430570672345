import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="container mx-auto px-5">
      <h1>404: Not Found</h1>
      <p>
        Click <Link to="/">here</Link> to return to the homepage.
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
